import getCookie from "../../getCookie";

const packPricingObject = ({
  answers,
  name,
  email,
  phone,
  optionalInfo,
  clid,
  estimate,
  linkCount,
  emails,
  verifyCount,
  tsUnsubscribe,
  initialEstimate,
  devSteps,
  samples,
  course1,
  course2,
  id,
  server,
}) => ({
  name,
  email,
  phone,
  config: {
    answers: answers.toJS(),
    optionalInfo: optionalInfo.toJS(),
    linkCount,
    emails,
    estimate,
  },
  clid,
  verifyCount,
  tsUnsubscribe,
  initialEstimate,
  devSteps,
  samples,
  course1,
  course2,
  id,
  server,
  uid: typeof window !== "undefined" && getCookie("uid"),
});

export default packPricingObject;

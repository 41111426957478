/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import styles from "./styles.module.css";
import classConcat from "../../../../../util/ClassConcat";
import MoreFormRow from "../../../../../layouts/pricing/question/fundingType/moreForm/moreFormRow";

export const INPUT_TYPE = {
  PHONE: "PHONE",
  NAME: "NAME",
  MESSAGE: "MESSAGE",
  OTHERS: "OTHERS",
};

const SignUpFormRow = ({
  title,
  inputType,
  selector,
  actionFn,
  validator,
  type,
  hint,
}) => {
  // Hooks
  const Pricing = useSelector((state) => state.Pricing);
  const { email, name, phone, tempEmail, message } = Pricing;
  const value = useSelector(selector);
  const dispatch = useDispatch();
  const [moreOther, setMoreOther] = useState("");
  const [moreArray, setMoreArray] = useState({});
  // Events
  if (title === "job") {
    title = "";
    var MoreQuestionsData = ["Want to connect", "Do you have any skills"];
  } else {
    var MoreQuestionsData = [
      { key: "estimate", name: "Get App Development Plan." },
      { key: "advice", name: "Get Strategy Advice." },
      { key: "value", name: "Get Valuation for funding."},
    ];
  }

  useEffect(() => {
    setMoreArray(message);
    setMoreOther(message?.other ?? "");
  }, []);
  const handleDefaultChange = (e) => {
    const action = actionFn(e.target.value);
    dispatch(action);
  };
  console.log({ message });
  const handleMoreOtherInput = (e) => {
    const t = { ...message, other: e.target.value };
    const action = actionFn({ ...message, other: e.target.value });
    dispatch(action);
    setMoreOther(e.target.value);
  };

  const handleMoreCheckboxAnswer = (e) => {
    if (e.target.checked) {
      setMoreArray({ ...moreArray, [e.target.name]: e.target.value });

      const action = actionFn({
        ...moreArray,
        [e.target.name]: e.target.value,
      });
      dispatch(action);
    } else {
      setMoreArray({ ...moreArray, [e.target.name]: "" });
      const action = actionFn({ ...moreArray, [e.target.name]: "" });
      dispatch(action);
    }
  };

  const handlePhoneChange = (phone, country, e, phoneFormatted) => {
    const action = actionFn(phone, phoneFormatted);
    dispatch(action);
  };
  // Computations
  const isValid = value && validator(value);
  // Render
  return (
    <div className="row px-3 py-1">
      <div className="col-12">
        <span className={styles.title}>{title}</span>
      </div>
      <div className="col-12">
        {(() => {
          switch (type) {
            case INPUT_TYPE.PHONE:
              return (
                <PhoneInput
                  country="us"
                  value={phone}
                  inputClass={classConcat(
                    styles.input,
                    isValid === false ? styles.inputInvalid : null,
                    isValid === true ? styles.inputValid : null
                  )}
                  inputProps={{
                    required: true,
                  }}
                  onChange={(e) => handlePhoneChange(e)}
                />
              );
            case INPUT_TYPE.NAME:
              return (
                <input
                  defaultValue={name}
                  type={inputType}
                  required
                  className={classConcat(
                    styles.input,
                    isValid === false ? styles.inputInvalid : null,
                    isValid === true ? styles.inputValid : null
                  )}
                  onChange={(e) => handleDefaultChange(e)}
                />
              );
            case INPUT_TYPE.MESSAGE:
              return (
                <>
                  {MoreQuestionsData.map((singleAnswer, ind) => (
                    <div
                      key={ind}
                      className={styles.input}
                      style={{
                        backgroundColor: "white",
                        textAlign: "left",
                        padding: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id={ind}
                        name={singleAnswer?.key}
                        value={singleAnswer?.name}
                        checked={
                          message
                            ? message[singleAnswer?.key]
                              ? true
                              : false
                            : false
                        }
                        onChange={(e) => handleMoreCheckboxAnswer(e)}
                      />
                      <label
                        style={{ marginLeft: "10px", marginBottom: "0px" }}
                      >
                        {singleAnswer?.name}
                      </label>
                      <br></br>
                    </div>
                  ))}
                </>
              );
            case INPUT_TYPE.OTHERS:
              return (
                <input
                  type="text"
                  placeholder={"Other"}
                  className={styles.input}
                  style={{ backgroundColor: "white", textAlign: "left" }}
                  value={moreOther}
                  onChange={(e) => handleMoreOtherInput(e)}
                />
              );
            default:
              return (
                <input
                  defaultValue={email}
                  type={inputType}
                  required
                  className={classConcat(
                    styles.input,
                    isValid === false ? styles.inputInvalid : null,
                    isValid === true ? styles.inputValid : null
                  )}
                  onChange={(e) => handleDefaultChange(e)}
                />
              );
          }
        })()}
      </div>
    </div>
  );
};

SignUpFormRow.propTypes = {
  title: PropTypes.string.isRequired,
  inputType: PropTypes.string.isRequired,
  selector: PropTypes.func.isRequired,
  actionFn: PropTypes.func.isRequired,
  validator: PropTypes.func.isRequired,
  type: PropTypes.string,
  hint: PropTypes.string.isRequired,
  email: PropTypes.string,
};

SignUpFormRow.defaultProps = {
  type: null,
  email: "",
};

export default SignUpFormRow;
